import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import {getSubCategories} from '../http'

export default function SubMenuItem({post_type, category, label}){

    const [subCategories, setSubCategories] = useState([])

    useEffect(() => {
        const fetchSubCategory = async () => {
            try {
                const {data} = await getSubCategories(post_type, category);
                setSubCategories(data.subCategories)
            } catch (error) {
                console.log(error)
            }
        }
        fetchSubCategory()
    }, [post_type, category])


    return (
        <li className="nav-item" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <NavLink to={(post_type == 'upsc-cse' && category == 'mains') ? '/mains' : `/posts/${post_type}/${category}`}><p className={`nav-link`}>{label}</p></NavLink>
        {subCategories.length >= 1 && (<ul className="sub-menu sub-menu-2">
          {
            subCategories.map((sc) => <NavLink to={`/posts/${post_type}/${category}?ct=${sc.slug}`}><p className={`nav-link`}>{sc.label}</p></NavLink>)
          }
        </ul>)}
        {(post_type == 'upsc-cse' && category == 'mains') && (<ul className="sub-menu sub-menu-2">
          
            <NavLink to={`/posts/upsc-cse/essay`}><p className={`nav-link`}>Essay</p></NavLink>
            <NavLink to={`/posts/upsc-cse/gs`}><p className={`nav-link`}>Gs</p></NavLink>
            <NavLink to={`/posts/upsc-cse/optional`}><p className={`nav-link`}>Optional</p></NavLink>
            <NavLink to={`/posts/upsc-cse/personality-test`}><p className={`nav-link`}>Personality Test</p></NavLink>
          
        </ul>)}
        {
            subCategories.length >= 1 && (
                <div style={{transform: 'rotate(-90deg)', paddingRight: '8px'}}>
                    <svg style={{width: '14px', marginLeft: '5px', transform: 'translateY(3px)'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
              </svg>
                </div>
            )
        }
        {
            (post_type == 'upsc-cse' && category == 'mains') >= 1 && (
                <div style={{transform: 'rotate(-90deg)', paddingRight: '8px'}}>
                    <svg style={{width: '14px', marginLeft: '5px', transform: 'translateY(3px)'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
              </svg>
                </div>
            )
        }
      </li>
    )
}