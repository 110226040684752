import {createSlice} from '@reduxjs/toolkit';

const initialState = {postTypes: [
]}

const postTypes = createSlice({
    name: 'postTypes',
    initialState,
    reducers: {
        addPostType: (state, action) => {
           state.postTypes = action.payload
        }
    }
});

export const {addPostType} = postTypes.actions;
export default postTypes.reducer;