import PageHeader from "../components/PageHeader";
import {Helmet} from 'react-helmet'

export default function About () {
    return (
        <div>

            <Helmet>
                <meta charSet="utf-8" />
                <title>About Us - Metis IAS</title>
            </Helmet>

            <PageHeader label='About Us' />
            <section id="about" style={{padding: '60px 0'}}>
               <div className="my-container">
                <div className="contact-grid">
                    <div className="contact-left about-left">
                        <img alt="about-img" className="about-big" src="/assets/img/about1.webp" />
                        <img alt="about-img" className="about-sm" src="/assets/img/about2.webp" />
                    </div>
                    <div className="cotact-right">
                        <div className="about-heading">
                            <h3 className="accent-heading">About Us</h3>
                            <h1 className="primary-heading">Welcome to Metis IAS Academy</h1>
                        </div>
                        <p style={{marginBottom: '50px'}}>At Metis IAS Academy, we believe in nurturing the leaders of tomorrow by providing comprehensive coaching for the Civil Services Examination. With a commitment to excellence and a proven track record of success, we are your trusted partner on the journey to realizing your IAS dreams.</p>
                        <div className="about-indo-box">
                            <div className="about-info-left">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
</svg>
                            </div>
                            <div className="about-info-right">
                                <h2 style={{marginBottom: '10px'}} className="secondary-heading">Flexible learning option</h2>
                                <p>We understand the diverse needs of aspirants. Choose from our flexible learning options, including both offline and online programs, designed to make quality education accessible to everyone.</p>
                            </div>
                        </div>
                        <div className="about-indo-box" style={{marginTop: '30px'}}>
                            <div className="about-info-left">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
</svg>
                            </div>
                            <div className="about-info-right">
                                <h2 style={{marginBottom: '10px'}} className="secondary-heading">Embark on Your Journey With Metis IAS Academy</h2>
                                <p>Whether you are a seasoned aspirant or just beginning your IAS preparation, Metis IAS Academy is here to guide you at every step. Join us in this transformative learning experience that goes beyond traditional coaching and prepares you not just for exams but for a successful career in civil services.</p>
                            </div>
                        </div>
                    </div>
                </div>
               </div>
            </section>

            <section id="whyus" style={{padding: '60px 0'}}>
                <div className="my-container">
                    <div className="contact-heading">
                        <h2 className="accent-heading">Why Choose Us</h2>
                        <h1 className="primary-heading">Our Core Features</h1>
                    </div>
                    <div className="whyusgrid">
                        <div className="whyus-box">
                            <div className="whyus-icon whyus-icon-odd">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
</svg>
                            </div>
                            <h2 style={{marginBottom: '10px'}} className="secondary-heading">Innovative Teaching Methods</h2>
                            <p>We employs innovative teaching methods to make learning engaging and effective.</p>
                        </div>
                        <div className="whyus-box">
                            <div className="whyus-icon whyus-icon-even">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
</svg>
                            </div>
                            <h2 style={{marginBottom: '10px'}} className="secondary-heading">Comprensive Study Materials</h2>
                            <p>At Metis IAS Academy, we believe in the power of well-structured study materials.</p>
                        </div>
                        <div className="whyus-box">
                            <div className="whyus-icon whyus-icon-odd">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
</svg>
                            </div>
                            <h2 style={{marginBottom: '10px'}} className="secondary-heading">Personalized<br/> Guidance</h2>
                            <p>Our mentors provide one-on-one support, ensuring that each student receives the attention</p>
                        </div>
                        <div className="whyus-box">
                            <div className="whyus-icon whyus-icon-even">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
</svg>
                            </div>
                            <h2 style={{marginBottom: '10px'}} className="secondary-heading">Expert<br/> Faculty</h2>
                            <p>Our faculty members are dedicated to providing insightful guidance</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}