import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from 'react-slick';
import { useState, useEffect } from "react";
import {getCategoriesByPostType, getPosts} from '../http';
import {useNavigate} from 'react-router-dom';
import dayjs from "dayjs";

function NextBtn ({onClick}) {
    return (
        <button onClick={onClick} className="primary-button craouser-button craouser-next-button">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
</svg>
        </button>
    )
}

function PrevBtn ({onClick}) {
    return (
        <button onClick={onClick} className="primary-button craouser-button carousel-prev-button">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg>
        </button>
    )
}

export default function PostCarousel ({label, slug, type}) {

    var settings = {
        dots: false,
        arrow: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <NextBtn />,
        prevArrow: <PrevBtn />,
        spacing: 20,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };

    const navigate = useNavigate();

    const [categories, setCategories] = useState([]);
    const [posts, setPost] = useState([]);

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const {data} = await getCategoriesByPostType(slug)
                
                setCategories(data.categories)
            } catch (error) {
                console.log(error)
            }
        }
        fetchCategory();
    }, [slug]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const {data} = await getPosts(slug, 'all', 'all', 'all', 1);
                setPost(data.posts)
                console.log('cat', data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchPosts()
    }, [slug])

    if(categories.length == 0 && type == 'cat') return null;
    if(type == 'post' && posts.length == 0) return null


    return (
        <div className="post-cra-container">
            <div className="post-cre-header">
                <div className="post-cra-heading">
                    <h1 className="primary-heading">{label}</h1>
                </div>
            </div>
            <div className="post-cra-body">
                {type == 'cat' && <Slider {...settings}>
                   
                   {
                    categories.map((cat) => (
                        <div className="craousel-card" onClick={() => navigate(`posts/${slug}/${cat.slug}`)}>
                        <div className="craouser-card-box">
                        <div className="craousel-card-thumbnail">
                             <img src={cat.image} alt="thumbnail" />
                         </div>
                         <div className="craouser-card-heading">
                             <h2 className="secondary-heading">{cat.label}</h2>
                         </div>
                        </div>
                     </div>
                    ))
                   }

                </Slider>}

                {
                    type == 'post' && (
                        <Slider {...settings}>
                   
                   {
                    posts.map((post) => (
                        <div className="craousel-card" onClick={() => navigate(`/post/${slug}/${post.slug}`)}>
                        <div className="craouser-card-box">
                        <div className="craousel-card-thumbnail" style={{position: 'relative'}}>
                             <img src={post.image} alt="thumbnail" />
                             <div className="post-date">
                             <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-calendar4-week" viewBox="0 0 16 16">
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"/>
  <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
</svg>
<span style={{fontSize: '12px'}}>{dayjs(post.updatedAt).format('D MMMM, YYYY')}</span>
                             </div>
                         </div>
                         <div className="craouser-card-heading">
                             <h2 className="secondary-heading" style={{height: '40px', lineHeight: '20px'}}>{post.title.length > 45 ? post.title.slice(0,45) + '...' : post.title}</h2>
                         </div>
                        </div>
                     </div>
                    ))
                   }

                </Slider>
                    )
                }
            </div>
            
        </div>
    )
}