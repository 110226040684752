import PageHeader from "../components/PageHeader";
import {useParams, useNavigate} from 'react-router-dom';
import { useState, useEffect } from "react";
import {getPosts, getSubCategories} from '../http';
import Pagination from '@mui/material/Pagination';

export default function Blogs ({label, slug, postType}){

    const post_type = 'blogs';
    const  category = 'all';
    const [sub_category, setSubCategory] = useState('all');
    const [page, setPage] = useState(1);

    const [subCategories, setSubCategories] = useState([]);
    const [posts, setPosts] = useState([]);
    const [total, setTotal] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
       const fetchPosts = async () => {
        try {
            const {data} = await getPosts(post_type, category, sub_category, page);
            setPosts(data.posts);
            setTotal(data.total)
        } catch (error) {
            console.log(error)
        }
       }
       fetchPosts();
    }, [post_type, category, sub_category, page]);

    useEffect(() => {
        const fetchSubCategory = async () => {
            try {
                const {data} = await getSubCategories(post_type, category);
                setSubCategories(data.subCategories)
            } catch (error) {
                console.log(error)
            }
        }
        fetchSubCategory()
    }, [post_type, category])


    


    return (
        <div>
        <PageHeader label='Blogs' slug='' />
        <section id="post-type" style={{padding: '60px 0'}}>
            
         <div className="my-container">
         {subCategories.length > 1 && (<div className="sub-category-nav">
                <ul>
                    <li className={sub_category == 'all' ? "active" : ''} onClick={() => setSubCategory('all')}>All</li>
                    {
                        subCategories.map((sc) => <li className={sub_category == sc.slug ? "active" : ''} onClick={() => setSubCategory(sc.slug)}>{sc.label}</li>)
                    }
                </ul>
            </div>)}
            <div className="grid-container">
              
              {
                posts.map((post) => (
                    <div className="craousel-card" onClick={() => navigate(`/post/${post_type}/${post.slug}`)} style={{cursor: 'pointer'}}>
                    <div className="craouser-card-box">
                    <div className="craousel-card-thumbnail">
                         <img src="/assets/img/metis-image-placeholder.png" alt="thumbnail" />
                     </div>
                     <div className="craouser-card-heading">
                         <h2 className="secondary-heading">{post.title}</h2>
                     </div>
                    </div>
                 </div>
                ))
              }
                
               </div>
         </div>
         {total > 12 && (<div style={{display: 'flex', justifyContent: 'center', paddingTop: '30px'}}>
            <Pagination count={Math.ceil(total/12)} page={page} onChange={(e, v) => setPage(v)} variant="outlined" color="secondary" />
         </div>)}
        </section>
     </div>
    )
}