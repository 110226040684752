import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCategories } from "../http";

export default function SidebarItem ({label, slug, id}) {

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const {data} = await getCategories(id);
                setCategories(data.categories)
            } catch (error) {
                console.log(error)
            }
        }
        fetchCategory()
    }, [id])

    return (
        <li className="sidebar-link-group">
            <strong>
                                            <p style={{ display: 'flex', alignItems: 'center' }}>

                                                <NavLink to={`/posts/${slug}`}>
                                                    <a href='#' className="footer-link sidebar-link-m" style={{display: 'flex', alignItems: 'center'}}>
                                                    <svg style={{marginRight:'7px'}} xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg>
                                                        {label}
                                                    </a>
                                                </NavLink>
                                            </p>
                                        </strong>
                                        {
                                            categories.length > 0 ? (
                                                <ul className="sidebar-category-child" style={{flexDirection: 'column'}}>
                                           {
                                            categories.map((cat) => (
                                                <li>
                                                <p style={{ display: 'flex', alignItems: 'center' }}>
                                                    <NavLink to={`/posts/${slug}/${cat.slug}`}>
                                                        <a href='#' className="footer-link sidebar-link-c" style={{display: 'flex', alignItems: 'center'}}>
                                                        <svg style={{marginRight:'7px'}} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg>
                                                            {cat.label.length > 15 ? cat.label.slice(0, 15) + '..' : cat.label}
                                                        </a>
                                                    </NavLink>
                                                </p>
                                            </li>
                                            ))
                                           }
                                        </ul>
                                            ) : null
                                        }
                                    </li>
    )
}