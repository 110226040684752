import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCategories } from "../http";
import SubMenuItem from "./SubMenuItem";




export default function MenuItem({label, slug, id, setCollapsed}){
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([])

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const {data} = await getCategories(id);
                setCategories(data.categories)
            } catch (error) {
                console.log(error)
            }
        }
        fetchCategory()
    }, [id])

    


    return (
        <li className="nav-item" style={{height: '100%'}}>
          <NavLink to={`/posts/${slug}`} onClick={() => setCollapsed(true)}>
              <p className={`nav-link`} >
                  {label}
                  {
                    categories.length > 0 ? (
                        <svg style={{width: '14px', marginLeft: '5px', transform: 'translateY(3px)'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                        </svg>
                    ) : null
                  }
              </p>
          </NavLink>
          {
            categories.length > 0 ? (
            <ul className="sub-menu">
               {
                categories.map((ct) => ct.menu_item && (
                  <SubMenuItem post_type={slug} category={ct.slug} label={ct.label} />
                ))
               }
            </ul>
            ) : null
          }
        </li>
    )
}