
import PageHeader from "../components/PageHeader";

import { useState, useEffect } from "react";
import {getCategoriesByPostType} from '../http';
import {useSelector} from 'react-redux';
import {useParams, useNavigate} from 'react-router-dom'



export default function PostType () {

    const [categories, setCategories] = useState([]);
    const {post_type} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
               const {data} = await getCategoriesByPostType(post_type);
               setCategories(data.categories)
            } catch (error) {
                console.log(error)
            }
        }
        fetchCategories()
    }, [post_type])

    


    return (
        <div>
           <PageHeader label={post_type.split('-').map(t => t[0].toUpperCase() + t.slice(1, t.length)).join(' ')} slug='' />
           <section id="post-type" style={{padding: '60px 0'}}>
            
            <div className="my-container">
               <div className="grid-container">

                {
                    categories.map((ct) => ct.menu_item && (
                        <div className="craousel-card" style={{cursor: 'pointer'}} onClick={() => navigate(post_type == 'upsc-cse' && ct.slug == 'mains' ? '/mains' : `/posts/${post_type}/${ct.slug}`)}>
                        <div className="craouser-card-box">
                        <div className="craousel-card-thumbnail">
                             <img src={ct.image} alt="thumbnail" />
                         </div>
                         <div className="craouser-card-heading">
                             <h2 className="secondary-heading">{ct.label}</h2>
                         </div>
                        </div>
                     </div>
                    ))
                }

                </div>
            </div>
           </section>
        </div>
    )
}