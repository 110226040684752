import PageHeader from "../components/PageHeader";
import { getPost, getCategories, getPosts } from "../http";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import SidebarItem from "../components/SidebarItem";
import { useSelector } from "react-redux";
import {Helmet} from 'react-helmet';
import dayjs from "dayjs";

export default function Post () {

    const {post_type, slug} = useParams();
    const [post, setPost] = useState({});

    const [posts, setPosts] = useState([]);

    const {postTypes} = useSelector(state => state.postTypes)

    useEffect(() => {
        const fetchPosts = async () => {
         try {
             const {data} = await getPosts(post_type, 'all', 'all', 'all', 1);
             setPosts(data.posts);
             
         } catch (error) {
             console.log(error)
         }
        }
        fetchPosts();
     }, [post_type]);
   

    

    useEffect(() => {
       const fetchPost = async () => {
        try {
            const {data} = await getPost(post_type, slug);
            setPost(data.post)
            console.log('post', data)
        } catch (error) {
            console.log(error)
        }
       }
       fetchPost()
    }, [post_type, slug])




    return (
        
        <div>
             <Helmet>
                <meta charSet="utf-8" />
                <title>{post.meta_title}</title>
                <meta name="description" content={post.meta_description} />
            </Helmet>
            <PageHeader label={post.title} slug={''} />
            <section id="post">
                <div className="my-container">
                    <div className="post-box">
                    <div className="post-sidebar post-sidebar-left">
                        <div className="sidebar-inner">
                           <h2 className="secondary-heading sidebar-title" style={{marginBottom: '40px', fontSize: '25px'}}>Related Post</h2>
                           <ul className="sidebar-category-child related-post" style={{flexDirection: 'column'}}>
                                           {
                                            posts.map((pt) => (
                                                <li>
                                                <p style={{ display: 'flex', alignItems: 'center' }}>
                                                    <NavLink to={`/post/${pt.post_type.slug}/${pt.slug}`}>
                                                        <a href='#' className="footer-link sidebar-link-c" style={{display: 'flex', alignItems: 'center'}}>
                                                        <svg style={{marginRight:'7px'}} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg>
                                                            {pt.title.length > 12 ? pt.title.slice(0, 12) + '..' : pt.title}
                                                        </a>
                                                    </NavLink>
                                                </p>
                                            </li>
                                            ))
                                           }
                                        </ul>
                                        {/* <div className="related-post">
                                           <h2 className="secondary-heading sidebar-title" style={{marginBottom: '20px', fontSize: '25px'}}>Subscribe Now</h2>
                                        </div> */}
                        </div>
                    </div>
                        <div className="post-content">
                            
                        <div>
                        <div style={{padding: '60px 0 20px 0'}}>
                                <strong><p style={{color: 'black', fontWeight: '700', opacity: '.6'}}>Last Updated On: {dayjs(post.updatedAt).format('D MMMM, YYYY')}</p></strong>
                            </div>
                           <style>
                            
                            {post.styles || ''}
                            </style>
                            <div dangerouslySetInnerHTML={{__html: post.content}}></div>
                            
                           </div>
                        </div>
                        <div className="post-sidebar post-sidebar-right">
                            <div className="sidebar-inner">
                                <h2 className="secondary-heading sidebar-title" style={{marginBottom: '30px', fontSize: '25px'}}>Categories</h2>
                                <ul className="sidebar-categories-list" style={{flexDirection: 'column'}}>
                                    {
                                        postTypes.map((pt) => pt.slug != 'blogs' && <SidebarItem label={pt.label} slug={pt.slug} id={pt._id} />)
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}